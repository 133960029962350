import numeral from 'numeral'

// Register number locales
numeral.register('locale', 'intl', {
  delimiters: {
      thousands: ' ',
      decimal: '.',
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: '€'
  }
});

numeral.register('locale', 'nl', {
  delimiters: {
      thousands: '.',
      decimal  : ','
  },
  abbreviations: {
      thousand : 'k',
      million  : 'mln',
      billion  : 'mrd',
      trillion : 'bln'
  },
  ordinal : function (number) {
      let remainder = number % 100;
      return (number !== 0 && remainder <= 1 || remainder === 8 || remainder >= 20) ? 'ste' : 'de';
  },
  currency: {
      symbol: '€ '
  }
});