import Vue from 'vue'
import dayjs from 'dayjs'
import numeral from 'numeral'

Vue.filter('dateFormat', function (value) {
  if (!value) return '-'
  if (value.date) value = value.date
  return dayjs(value).format('MMM D, YYYY')
})

Vue.filter('datetimeFormat', function (value) {
  if (!value) return '-'
  if (value.date) value = value.date
  return dayjs(value).format('DD-MM-YYYY H:mm')
})

Vue.filter('yesno', function (value) {
  value = (value === true || value == '1' || value == 1 || value == 'true' || value == 'True')
  return value ? 'Yes' : 'No';
})

Vue.filter('formatAccountingPeriod', function (value) {
  return dayjs(value, 'YYMM').format('MMM YYYY')
})

//Vue.filter('numberFormat', function (value) {
//  return numeral(value).format('0.[0000]')
//})

Vue.filter('numberFormat', function (value, decimals = 0, locale = 'nl') {
  if (isNaN(value)) return '-'

  numeral.locale(locale)

  return numeral(parseFloat(value)).format('0,0.'+'0'.repeat(decimals))
})

Vue.filter('jsonPretty', function (value) {
  return JSON.stringify(value, null, 2)
})
